import React from 'react'

import Navbar from '../components/Navbar'
import HeroImage from '../components/HeroImage'
import Footer from '../components/Footer'
const Home = () => {
  return (
    <div>
      <Navbar />
    <HeroImage />
    <Footer />
    </div>
  )
}

export default Home