import './FooterStyles.css';
import React from 'react'
import { FaFacebook, FaHome, FaLinkedin, FaMailBulk, FaPhone,  FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <div className='left'>
                <div className='location'>
                    <FaHome size={20} style={{color:"#fff",marginRight:"2rem"}}/>
                    <div>
                        <p>Electronoc city,</p>
                        <p>Bangalore</p>
                    </div>
                </div>
                <div className='phone'>
                    <h4> <FaPhone size={20} style={{color:"#fff",marginRight:"2rem"}} />
                    +91 9606151648</h4>
                   
                </div>
                <div className='email'>
                    <h4> <FaMailBulk size={20} style={{color:"#fff",marginRight:"2rem"}} />
                    meerabmishra102@gmail.com</h4>
                   
                </div>

            </div>
            <div className='right'>
                <h4>About the company</h4>
                <p>
                With over four decades of experience ,
                 it is a trusted partner to the financial 
                 industry, transforming middle and back-office
                  operations across the globe. Today Smartstream solely
                   owned 
                and funded by the Investment Corporation of Dubai.
                </p>
                <div className='social'>
                <FaFacebook size={30} style={{color:"#fff",marginRight:"1rem"}} />
                <FaTwitter size={30} style={{color:"#fff",marginRight:"1rem"}} />

                <FaLinkedin size={30} style={{color:"#fff",marginRight:"1rem"}} />

                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer