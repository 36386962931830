import './HeroImageStyle.css';
import React from 'react'
import IntroImg from "../assets/intro-bg.jpg"
import { Link } from 'react-router-dom';

const HeroImage = () => {
  return (
    <div className='hero'>
        <div className='mask'>
            <img className='intro-img' src={IntroImg} alt="IntroImg"/>

        </div>
        <div className='content'>
            <p>Hi, I'M A Freelancer</p>
            <h1>React Developer.</h1>
            <div>
                <Link to='/Project' className='btn'>PROJECTS</Link>
                <Link to='/Contact' className='btn btn-light'>CONTACT</Link>
            </div>
        </div>
    </div>
  )
}

export default HeroImage
