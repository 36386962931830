import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './index.css'
import About from './routes/About';
import Contact from './routes/Contact';
import Home from './routes/Home';
import Project from './routes/Project';


function App() {
  return (
    <div>
     <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/Project' element={<Project/>}/>
      <Route path='/About' element={<About />}/>
      <Route path='/Contact' element={<Contact />}/>
     </Routes>
    </div>
  );
}

export default App;
