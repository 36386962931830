import pro1 from '../assets/project1.png'
import pro2 from '../assets/project2.png'
import pro3 from '../assets/project3.png'

const WorkCardData = [
    {
        imgsrc: pro1,
        title: "Fitclub app",
        text: "This React project aims to automate gym and fitness admission process it gives you information about the gym subscription plan and benefits",
        view: "https://fitclub-3i8.pages.dev/",
        source: "https://github.com/meerabmishra/fitclub/tree/gh-pages/src"
    },
    {
        imgsrc: pro2,
        title: "Expense-Income Tracker",
        text: "This project aims to create an easy, faster and smooth tracking system between expense and income. It supports voice command",
        view: "https://expense-income-tracker.pages.dev/",
        source: "https://github.com/meerabmishra/Income-Expense-Tracker-ReactApp/tree/main/src"
    },
    {
        imgsrc: pro3,
        title: "Quiz app",
        text: "Quiz App is a React based Application that has general questions related to current affairs with rewards. There will be limited no of questions and for each correct answers user will be awarded with prize money.",
        view: "https://quizapp-4sb.pages.dev/",
        source: "https://github.com/meerabmishra/quiz-reactApp/tree/gh-pages/src"
    }

]

export default WorkCardData;